import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link,NavLink } from 'react-router-dom';
import logo from '../img/danel-logo_1x.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../style/Nav.css';

const Navigation = () => {
  const navItems = [
    { path: "/", name: "HOME" },
    { path: "/about-us", name: "ABOUT US" },
    { path: "/who-we-are", name: "WHO ARE WE" },
    { path: "/how-we-do-it", name: "HOW WE DO IT" },
    { path: "/contact-us", name: "CONTACT US" },
    { path: "/donate-now", name: "DONATE NOW", isButton: true }
  ];

  return (
    <div className="sticky-top ">
      {/* Top Bar */}
      <div className="top-bar py-2 d-none d-lg-block">
        <Container>
          <Row>
            <Col>
              <div className="d-flex">
                <Link to="tel:+1-541-754-3010" className="d-flex align-items-center text-decoration-none text-white">
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  <span>+416-666-7754
                  +1-855-833-4247</span>
                </Link>
                <Link to="mailto:info@gmail.com" className="d-flex align-items-center ms-4 text-decoration-none text-white">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  <span>contact@danielfoundation.net</span>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Link to="#" className="text-white me-3">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Link>
                <Link to="#" className="text-white me-3">
                  <FontAwesomeIcon icon={faTwitter} />
                </Link>
                <Link to="#" className="text-white">
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Main Navigation */}
      <Navbar expand="lg" className="main-navbar">
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Navbar.Brand as={Link} to="/">
              <img src={logo} alt="Logo" style={{height:"70px"}} />
            </Navbar.Brand>
          </motion.div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {navItems.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: index * 0.1 }}
                >
                  {item.isButton ? (
                    <NavLink
                      to={item.path}
                      className="nav-link donate-btn"
                    >
                      {item.name}
                    </NavLink>
                  ) : (
                    <NavLink  to={item.path}  className={({ isActive }) => `nav-link ${isActive ? ' active ' : ''}`}>
                      {item.name}
                    </NavLink>
                  )}
                </motion.div>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;