import React, { useState,useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaPaperPlane, FaMapMarkerAlt, FaPhone, FaEnvelope, FaHandHoldingHeart } from 'react-icons/fa';
import '../style/ContactUs.css'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// Import partner logos (replace with actual image imports)
import digitalParadiseLogo from '../img/digital logo.png';
import tigrayHealthLogo from '../img/tigray logo.webp';
import danielFoundationLogo from '../img/danel logo.png';
import stGeorgeCanadaLogo from '../img/The-Order-logo.jpg';
import stGeorgeAmericasLogo from '../img/The-Order-Americasp logo.jpeg';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
    alert('Thank you for your message! We will get back to you soon.');
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  const partners = [
    {
      id: 1,
      logo: digitalParadiseLogo,
      title: "Tigray Development Association in North America",
      content: "LETTER OF SUPPORT",
      link: "#"
    },
    {
      id: 2,
      logo: tigrayHealthLogo,
      title: "Tigray Interim Administration Bureau of Health",
      content: "click- Announcement",
      link: "#"
    },
    {
      id: 3,
      logo: danielFoundationLogo,
      title: "From Daniel Foundation",
      content: "click- TO READ",
      link: "#"
    },
    {
      id: 4,
      logo: stGeorgeCanadaLogo,
      title: "The Order of St. George of Canada",
      content: "click- TO READ",
      link: "#"
    },
    {
      id: 5,
      logo: stGeorgeAmericasLogo,
      title: "The Order of St. George of the Americas",
      content: "click- TO READ",
      link: "#"
    }
  ];
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },[])

  return (
    <>
    <Helmet>
        {/* Page Title */}
        <title>Contact Us - The Daniel Foundation | Make a Difference Today</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Get in touch with The Daniel Foundation. Contact us for any questions, donations, or volunteer inquiries. Together, we can make a difference!"
        />

        {/* Keywords for SEO */}
        <meta
          name="keywords"
          content="contact us, donation, volunteer, The Daniel Foundation, get involved, support, Tigray Interim Administration, Order of St. George, humanitarian organization"
        />

        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:title" content="Contact Us - The Daniel Foundation | Make a Difference Today" />
        <meta
          property="og:description"
          content="Reach out to The Daniel Foundation to learn more about our mission, donate, or volunteer. Make a lasting impact today!"
        />
        <meta property="og:image" content="https://www.danielfoundation.net/images/contact-us-image.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.danielfoundation.net/contact-us" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Contact Us - The Daniel Foundation | Make a Difference Today" />
        <meta
          name="twitter:description"
          content="Contact The Daniel Foundation to support our cause or get involved. Your help can change lives."
        />
        <meta name="twitter:image" content="https://www.danielfoundation.net/images/contact-us-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    <section className="contact-section py-5" style={{ backgroundColor: '#f8f9fa' }}>
      <div className="container">
        {/* Header with Animation */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-5"
        >
          <h1 className="display-4 fw-bold" style={{ color: '#d62d20' }}>
            Contact Us & <span style={{ color: '#000' }}>Make a Difference Today!</span>
          </h1>
          <p className="lead" style={{ maxWidth: '800px', margin: '0 auto' }}>
            Thank you for being so interested in The Daniel Foundation. We will love to hear from you if you have any questions, comments, or suggestions. Whether you want to learn more about our mission, donate to our cause, or get involved as a volunteer, we are here to help.
          </p>
        </motion.div>

        <div className="row g-4">
          {/* Contact Form */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="col-lg-7"
          >
            <div className="card shadow-lg border-0 rounded-4 h-100">
              <div className="card-body p-4 p-md-5">
                <h3 className="mb-4" style={{ color: '#d62d20' }}>Send us a message</h3>
                <p className="mb-4">
                  To contact us, please use the form below or email us at<br />
                  <span className="d-block mt-2">
                    <FaEnvelope className="me-2" />
                    <a href="mailto:meron@danielfoundation.net" className="text-decoration-none">meron@danielfoundation.net</a> | 
                    <a href="mailto:belay@danielshfir.org" className="text-decoration-none"> belay@danielshfir.org</a> | 
                    <a href="mailto:charles@danielshfir.org" className="text-decoration-none"> charles@danielshfir.org</a> | 
                    <a href="mailto:kilbrow@danielshfir.org" className="text-decoration-none"> kilbrow@danielshfir.org</a>
                  </span>
                </p>
                
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">NAME</label>
                    <input 
                      type="text" 
                      className="form-control rounded-pill" 
                      id="name" 
                      name="name" 
                      value={formData.name}
                      onChange={handleChange}
                      required 
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">EMAIL</label>
                    <input 
                      type="email" 
                      className="form-control rounded-pill" 
                      id="email" 
                      name="email" 
                      value={formData.email}
                      onChange={handleChange}
                      required 
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">SUBJECT</label>
                    <input 
                      type="text" 
                      className="form-control rounded-pill" 
                      id="subject" 
                      name="subject" 
                      value={formData.subject}
                      onChange={handleChange}
                      required 
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="form-label">MESSAGE</label>
                    <textarea 
                      className="form-control rounded-3" 
                      id="message" 
                      name="message" 
                      rows="4"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05, backgroundColor: '#b0251b' }}
                    whileTap={{ scale: 0.95 }}
                    type="submit" 
                    className="btn btn-danger rounded-pill px-4 py-2 d-flex align-items-center"
                    style={{ backgroundColor: '#d62d20' }}
                  >
                    <FaPaperPlane className="me-2" /> Send
                  </motion.button>
                </form>
              </div>
            </div>
          </motion.div>

          {/* Contact Info */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="col-lg-5"
          >
            <div className="d-flex flex-column h-100 gap-4">
              {/* Address Card */}
              <div className="card shadow-sm border-0 rounded-4 flex-grow-1">
                <div className="card-body p-4 d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <div className="bg-danger bg-opacity-10 p-3 rounded-circle me-3">
                      <FaMapMarkerAlt size={24} style={{ color: '#d62d20' }} />
                    </div>
                    <h4 className="mb-0" style={{ color: '#d62d20' }}>Address</h4>
                  </div>
                  <p className="mb-4">
                    Unit 65 - 3120 Sheppard Ave East<br />
                    North York, Ontario<br />
                    CANADA
                  </p>
                  
                  {/* Map */}
                  <div className="mt-auto rounded-3 overflow-hidden" style={{ height: '200px' }}>
                    <iframe 
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.23456789!2d-79.12345678901234!3d43.12345678901234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDPCsDA3JzI0LjQiTiA3OcKwMDcnMjQuMCJX!5e0!3m2!1sen!2sca!4v1234567890123!5m2!1sen!2sca" 
                      width="100%" 
                      height="100%" 
                      style={{ border: 0 }} 
                      allowFullScreen="" 
                      loading="lazy" 
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Location Map"
                    ></iframe>
                  </div>
                </div>
              </div>

              {/* Phone & Donate Cards */}
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="card shadow-sm border-0 rounded-4 h-100">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center mb-3">
                        <div className="bg-danger bg-opacity-10 p-3 rounded-circle me-3">
                          <FaPhone size={24} style={{ color: '#d62d20' }} />
                        </div>
                        <h4 className="mb-0" style={{ color: '#d62d20' }}>Phone</h4>
                      </div>
                      <p className="mb-0">
                        <a href="tel:416-666-7754" className="text-decoration-none text-dark">416-666-7754</a><br />
                        <a href="tel:1-855-833-4247" className="text-decoration-none text-dark">1-855-833-4247</a>
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-6">
                  <motion.div
                    whileHover={{ y: -5 }}
                    className="card shadow-sm border-0 rounded-4 h-100"
                    style={{ backgroundColor: '#d62d20', color: 'white' }}
                  >
                    <div className="card-body p-4 d-flex flex-column">
                      <div className="d-flex align-items-center mb-3">
                        <div className="bg-white bg-opacity-20 p-3 rounded-circle me-3">
                          <FaHandHoldingHeart size={24} />
                        </div>
                        <h4 className="mb-0">Donate</h4>
                      </div>
                      <p className="mb-3">Support our cause and make a difference today!</p>
                      <Link to="/donate-now"className="">

                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="btn btn-light rounded-pill mt-auto align-self-start px-3"
                        style={{ color: '#d62d20' }}
                      >
                        DONATE TODAY
                      </motion.button>
                      </Link>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Our Partners Section */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="mt-5 pt-5"
        >
          <div className="text-center mb-4">
            <h2 className="fw-bold" style={{ color: '#d62d20' }}>Our Partners</h2>
            <p className="lead">We collaborate with these amazing organizations</p>
          </div>

          <div className="row g-4">
            {partners.map((partner) => (
              <div key={partner.id} className="col-md-4 col-lg-2 col-sm-6 mx-auto">
                <motion.div 
                  whileHover={{ y: -5 }}
                  className="card h-100 border-0 shadow-sm text-center p-3"
                >
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-3" style={{ height: '80px', display: 'flex', alignItems: 'center' }}>
                      <img 
                        src={partner.logo} 
                        alt={partner.title} 
                        className="img-fluid"
                        style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                      />
                    </div>
                    <h6 className="mb-2">{partner.title}</h6>
                    {/* <p className="small text-muted mb-0">{partner.content}</p> */}
                  </div>
                </motion.div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>

      {/* Decorative Elements */}
      <div className="position-absolute top-0 end-0">
        <div className="oval-shape bg-danger bg-opacity-10" style={{ width: '200px', height: '300px', borderRadius: '50%', transform: 'rotate(45deg)' }}></div>
      </div>
      <div className="position-absolute bottom-0 start-0">
        <div className="circle-shape bg-danger bg-opacity-10" style={{ width: '150px', height: '150px', borderRadius: '50%' }}></div>
      </div>
    </section>
    </>
  );
};

export default ContactUs;