import React,{useEffect} from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/WhoWeAre.css';
import { Link } from 'react-router-dom';
// Import images (replace with your actual image paths)
import founderImg from '../img/founder2.jpg';
import founderImg_2 from '../img/Meron & Kids.jpg'
import volunteer1 from '../img/volenter1.jpg';
import volunteer2 from '../img/volenter2.jpg';
import board1 from '../img/bord mr dawit.jpg';
import board2 from '../img/bord mis aziza.jpg';
import board3 from '../img/bord mr sisay.jpg';
import board4 from '../img/bord mis simret.jpg';
import board5 from '../img/bord mr mekonnen.jpg';
import board6 from '../img/bord dr kelemework.jpg';
import board7 from '../img/bord mr tesfay.jpg';
import board8 from '../img/bord mr  hafte.jpg';
import board9 from '../img/bord mr mesfin.jpg';
import Readmore from './Readmore';
import { founder_bio } from './dataDump';

const WhoWeAre = () => {
  const boardMembers = [
    { name: "Mr. Dawit Hailu", title: "Deputy Director", img: board1 },
    { name: "Mrs. Aziza Abdulaziz", title: "Secretary", img: board2 },
    { name: "Mr. Sissay Gebru", title: "Finance & Admin.", img: board3 },
    { name: "Mr. Simret Melles", title: "Finance & Admin.", img: board4 },
    { name: "Mr. Mekonnen Sileshi", title: "Coordinator", img: board5 },
    { name: "Dr. Kelemework Tedla", title: "Advisor and US Rep", img: board6 },
    { name: "Mr Tesfay Haddish Berhe", title: "Representative in Raya", img: board7 },
    { name: "Mr Hafte Abadi Weldemichaell", title: "Representative in Raya", img: board8 },
    { name: "Mr Mesfin", title: "Representative in Ethiopia", img: board9 }
  ];

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardHover = {
    scale: 1.03,
    boxShadow: "0 0 20px rgba(217, 35, 15, 0.6)",
    transition: { 
      type: "spring", 
      stiffness: 300,
      damping: 10 
    }
  };
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },[])
  return (
    <>  
     <Helmet>
        {/* Page Title */}
        <title>Who We Are - The Daniel Foundation | Meet Our Founder and Team</title>
        
        {/* Meta Description for SEO */}
        <meta 
          name="description" 
          content="Learn about Mrs. Meron Lemma, the founder of The Daniel Foundation, and our dedicated team. Join us in our mission to empower vulnerable communities in Ethiopia." 
        />
        
        {/* Keywords for SEO */}
        <meta 
          name="keywords" 
          content="The Daniel Foundation, Meron Lemma, humanitarian organization, Ethiopian founder, orphaned children, abused women, community welfare, volunteer opportunities, humanitarian aid, empowerment, support" 
        />
        
        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:title" content="Who We Are - The Daniel Foundation | Meet Our Founder and Team" />
        <meta 
          property="og:description" 
          content="Discover the inspiring journey of Meron Lemma, founder of The Daniel Foundation, and our dedicated team working to make a difference in Ethiopia. Join us as a volunteer or donor." 
        />
        <meta property="og:image" content="https://www.danielfoundation.net/images/founder-profile.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.danielfoundation.net/who-we-are" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Who We Are - The Daniel Foundation | Meet Our Founder and Team" />
        <meta name="twitter:description" content="Get to know Meron Lemma and the passionate team behind The Daniel Foundation. Your support can help transform lives in Ethiopia." />
        <meta name="twitter:image" content="https://www.danielfoundation.net/images/founder-profile.jpg" />
        <meta name="twitter:card" content="https://www.danielfoundation.net/images/founder-profile.jpg" />
      </Helmet>
    <div className="who-we-are">
      {/* Volunteer Call to Action */}
      <motion.section 
        className="volunteer-cta py-5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} className="text-center">
              <motion.h2 
                className="cta-title mb-4"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                VOLUNTEERING WITH US
              </motion.h2>
              <motion.p className="cta-text mb-4">
                Join us in making a profound difference in the lives of orphaned children by volunteering with The Daniel Foundation.
              </motion.p>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="/contact-us"className="">
                <Button variant="danger" size="lg" className="cta-button">
                  Join Our Volunteer Team
                </Button>
                </Link>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.section>

      {/* Founder Section */}
      <section className="founder-section py-5">
        <Container>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="section-title text-center mb-5">Our Founder</h2>
          </motion.div>
          
          <Row className="align-items-center">
            <Col lg={4} className="mb-4 mb-lg-0">
              <motion.div
                whileHover={cardHover}
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
              >
                <Card className="founder-card">
                  <div className="image-container">
                    <Card.Img variant="top" src={founderImg_2} className="founder-img img-fluid" />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title className="fw-bold">Mrs. Meron Lemma</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Executive Director</Card.Subtitle>
                    <Card.Subtitle className="mb-3 fw-bold">Founder of The Daniel Foundation</Card.Subtitle>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            <Col lg={8}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: 0.2 }}
              >
                <div className="founder-bio">
                 <Readmore text={founder_bio} maxLength={1050}/>         
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Donation Call to Action */}
      <section className="py-5 bg-light">
        <Container>
          <Row>
            <Col md={6} className="mb-4 mb-md-0">
              <motion.div 
                className="donation-card h-100 p-4 rounded text-center"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                whileHover={cardHover}
              >
                <span className="text-muted d-block mb-2">Make a Difference</span>
        
                <h2 className="fw-bold display-6 mb-3">Donate Now</h2>
              
                <p className="mb-4">Your contribution will help us provide essential resources to orphaned children and abused women.</p>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                    <Link to="/donate-now"className="">
                  <Button variant="danger" size="lg" className="px-4">
                    Donate Today
                  </Button>
                  </Link>
                </motion.div>
              </motion.div>
            </Col>
            <Col md={6}>
              <motion.div 
                className="volunteer-cta-card h-100 p-4 rounded text-center"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                whileHover={cardHover}
              >
                <span className="text-light d-block mb-2">Join Our Cause</span>
                <h2 className="text-white fw-bold display-6 mb-3">Volunteer With Us</h2>
                <p className="text-light mb-4">Become part of our team and make a direct impact in children's lives.</p>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link to="/contact-us"className="">
                  <Button variant="outline-light" size="lg" className="px-4">
                    Sign Up to Volunteer
                  </Button>
                  </Link>
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Board Members Section */}
      <section className="board-section py-5">
        <Container>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="section-title text-center mb-5">The Board</h2>
            <p className="section-title  org">Organization Structure</p>
          </motion.div>
          
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            animate="visible"
          >
            <Row className="g-4">
              {boardMembers.map((member, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={4} xl={3}>
                  <motion.div
                    variants={fadeIn}
                    whileHover={cardHover}
                  >
                    <Card className="board-member-card h-100">
                      <div className="image-container">
                        <Card.Img variant="top" src={member.img} className="board-member-img" />
                      </div>
                      <Card.Body className="text-center">
                        <Card.Title className="fw-bold">{member.name}</Card.Title>
                        <Card.Subtitle className="text-muted">{member.title}</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </motion.div>
                </Col>
              ))}
            </Row>
          </motion.div>
        </Container>
      </section>

      {/* Volunteer Section */}
      <section className="volunteer-section py-5">
        <Container>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="section-title text-center mb-5">Our Volunteers</h2>
          </motion.div>
          
          <Row className="g-4">
            <Col md={6}>
              <motion.div
                whileHover={cardHover}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Card className="volunteer-card h-100">
                  <div className="image-container">
                    <Card.Img variant="top" src={volunteer1} className="volunteer-img" />
                  </div>
                  <Card.Body>
                    <Card.Text className="volunteer-text">
                      Our dedicated community of volunteers is committed to providing vital resources like food, cooking utensils, blankets, and hygiene products to abused women and orphanages in need.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
            <Col md={6}>
              <motion.div
                whileHover={cardHover}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Card className="volunteer-card h-100">
                  <div className="image-container">
                    <Card.Img variant="top" src={volunteer2} className="volunteer-img" />
                  </div>
                  <Card.Body>
                    <Card.Text className="volunteer-text">
                      We're seeking passionate individuals like you to join our cause. As a volunteer, you can directly impact these children's lives by working with them, organizing events, and fostering a sense of belonging.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
          
          <motion.div 
            className="text-center mt-5"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
                <Link to="/contact-us"className="">
              <Button variant="danger" size="lg" className="volunteer-button">
                Become a Volunteer Today
              </Button>
              </Link>
            </motion.div>
          </motion.div>
        </Container>
      </section>
    </div>
    </>
  
  );
};

export default WhoWeAre;